import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <title>404 | Η σελίδα δε βρέθηκε</title>

      <div className="container">
        <h1>Η σελίδα δε βρέθηκε</h1>
        <p style={{ textAlign: "center" }}>
          <Link to="/">Πίσω στην Αρχική</Link>.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
